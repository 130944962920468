<template>
  <div class="container">
    <div class="search-field my-3 d-flex flex-column">
      <span class="p-input-icon-left col-12 col-md-5">
        <i class="pi pi-search" />
        <InputText v-model="searchingName" placeholder="Search" aria-describedby="username-help" class="col-12"/>
      </span>
      <small class="helper-text mt-1">Введите более 2 символов</small>
    </div>
    <div class="teachers-list row row-cols-2" v-if="!selectedTeacherId && teachers.length > 0">
      <div class="col p-2" v-for="(teacher, index) in teachers" :key="index" @click="openTeacherCourses(teacher.pps_id)"
           style="cursor: pointer">
        <Card>
          <template #title>
            <h4 style="font-weight: 500; font-size: 22px">{{teacher.lastname}} {{teacher.firstname}} {{teacher.middlename}}</h4>
          </template>
          <template #content><h4 style="font-weight: normal">{{teacher.lastname_eng}} {{teacher.firstname_eng}}</h4></template>
        </Card>
      </div>
    </div>
    <div v-else>
      <div class="teachers-list" v-if="teacher_courses.ordinary.length > 0">
        <h2>Обычные курсы:</h2>
        <div class="row row-cols-2">
          <div class="col p-2" v-for="(course, index) in teacher_courses.ordinary" :key="index">
            <Card>
              <template #title><h4 style="font-weight: 500; font-size: 22px">{{course.course_name}}</h4></template>
              <template #content>
                <div class="d-flex justify-content-end">
                  <Button label="Открыть" icon="pi pi-external-link" iconPos="right" @click="openModal(course.mdl_course_id, 'open')"/>
                  <Button label="Закрыть" class="p-button-danger mx-2" icon="pi pi-external-link" iconPos="right" @click="openModal(course.mdl_course_id, 'close')"/>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>
      <div class="teachers-list" v-if="teacher_courses.summer.length > 0">
        <h2 class="mt-4">Летние курсы:</h2>
        <div class="row row-cols-2">
          <div class="col p-2" v-for="(course, index) in teacher_courses.summer" :key="index">
            <Card>
              <template #title><h4 style="font-weight: 500; font-size: 22px">{{course.name}}</h4></template>
              <template #content>
                <div class="d-flex justify-content-end">
                  <Button label="Импортировать" icon="pi pi-external-link" iconPos="right" @click="openSummerModal(course.mdl_course_id)"/>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>
    </div>

    <Dialog v-model:visible="modalVisible" modal header="Открытие ВСК дисциплины" :style="{ width: '25wv' }">
      <h5 style="font-weight: 500; font-size: 18px">{{teacher_courses.ordinary.find(i => i.mdl_course_id == selectedCourseId).course_name}}</h5>
      <SelectButton v-model="selectedVSKType" :options="VSKTypes"  allowEmpty="false"
                    optionLabel="name" optionValue="value"/>
      <template #footer>
        <Button :disabled="buttonLoading" :class="[activeModalMethod === 'close' ? '' : 'p-button-danger']" label="Отменить" icon="pi pi-times" @click="closeModal"/>
        <Button :disabled="buttonLoading" v-if="activeModalMethod === 'open'"
                label="Открыть ВСК" icon="pi pi-check" @click="openDisciplineVSK"/>
        <Button :disabled="buttonLoading" v-if="activeModalMethod === 'close'" class="p-button-danger"
                label="Закрыть ВСК" icon="pi pi-check" @click="closeDisciplineVSK"/>
      </template>
    </Dialog>

    <Dialog v-model:visible="summerModalVisible" modal header="Открытие ВСК дисциплины" :style="{ 'max-width': '1000px' }">
      <h5 style="font-weight: 500; font-size: 18px">{{teacher_courses.summer.find(i => i.mdl_course_id == selectedCourseId).name}}</h5>
      <DataTable :value="summer_ratings">
        <Column header="№">
          <template #body="{index}">
            {{ index+1 }}
          </template>
        </Column>
        <Column header="ФИО">
          <template #body="{data}">
            {{ data.last_name }} {{ data.first_name }}
          </template>
        </Column>
        <Column field="barcode" header="Barcode"></Column>
        <Column field="vsk1" header="ВСК 1"></Column>
        <Column field="vsk2" header="ВСК 2"></Column>
        <Column field="exam" header="Экзамен"></Column>
        <Column field="total" header="Итоговый"></Column>
      </DataTable>
      <template #footer>
        <Button :disabled="buttonLoading" class="p-button-secondary" label="Отменить" icon="pi pi-times" @click="closeSummerModal"/>
        <Button :disabled="buttonLoading" label="Импортировать оценки" icon="pi pi-check" @click="importSummerRatings"/>
      </template>
    </Dialog>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "DisciplineOpening",
  data(){
    return{
      searchingName: '',
      selectedTeacherId: null,
      selectedCourseId: null,
      selectedCourses: [],
      VSKTypes: [{name: 'ВСК 1', value: 1},{name: 'ВСК 2', value: 2}],
      selectedVSKType: 1,
      modalVisible: false,
      activeModalMethod: '',
      buttonLoading: false,
      summerModalVisible: false
    }
  },
  watch: {
    async searchingName(newVal, oldVal){
      if (newVal !== oldVal){
        this.selectedTeacherId = null
        this.closeModal()
        if (this.searchingName.length > 2){
          await this.GET_TEACHES(this.searchingName)
        }
      }
    }
  },
  computed: {
    ...mapState('officeRegistrationDisciplineOpening', ['teachers', 'teacher_courses', "summer_ratings"]),
  },
  methods: {
    ...mapActions('officeRegistrationDisciplineOpening', ['GET_TEACHES', 'GET_TEACHER_COURSES', 'GET_SUMMER_RATINGS', 'POST_OPEN_VSK', 'POST_CLOSE_VSK', "POST_IMPORT_SUMMER_RATINGS"]),
    ...mapMutations('officeRegistrationDisciplineOpening', ['SET_SUMMER_RATINGS']),
    async openTeacherCourses(teacher_id){
      this.selectedTeacherId = teacher_id
      await this.GET_TEACHER_COURSES(teacher_id);
    },
    openModal(mdl_course_id, method){
      this.modalVisible = true
      this.activeModalMethod = method
      this.selectedCourseId = mdl_course_id
    },
    closeModal(){
      this.modalVisible = false
      this.activeModalMethod = ''
      this.selectedCourseId = null
    },
    async openSummerModal(mdl_course_id){
      await this.GET_SUMMER_RATINGS(mdl_course_id)
      this.summerModalVisible = true
      this.selectedCourseId = mdl_course_id
    },
    closeSummerModal(){
      this.SET_SUMMER_RATINGS([])
      this.summerModalVisible = false
      this.selectedCourseId = null
    },
    async importSummerRatings(){
      this.buttonLoading = true
      let reqRes = await this.POST_IMPORT_SUMMER_RATINGS(this.selectedCourseId)
      if (reqRes){
        this.$message({title: 'Оценки успешно импортированы'})
      }
      await this.GET_SUMMER_RATINGS(this.selectedCourseId)
      this.buttonLoading = false
    },
    async openDisciplineVSK(){
      this.buttonLoading = true
      let reqRes = await this.POST_OPEN_VSK({mdl_course_id: this.selectedCourseId, vsk: this.selectedVSKType})
      if (reqRes){
        this.$message({title: 'ВСК успешно открыт'})
      }
      this.closeModal()
      this.buttonLoading = false
    },
    async closeDisciplineVSK(){
      this.buttonLoading = true
      let reqRes = await this.POST_CLOSE_VSK({mdl_course_id: this.selectedCourseId, vsk: this.selectedVSKType})
      if (reqRes){
        this.$message({title: 'ВСК успешно закрыт'})
      }
      this.closeModal()
      this.buttonLoading = false
    }
  },
}
</script>

<style scoped>
  .helper-text{
    color: #495057;
    font-weight: 400;
    font-size: 12px;
  }
</style>
